import styled from 'styled-components';
import type { CTAButtons as CTAButtonsSchema } from '@amplience/content-types/typings/c-cta-buttons';
import { useMediaMatch } from '@storefront/lib-global/hooks/useMediaMatch';
import { FlexibleTextPartial } from '@storefront/lib-global/partials/flexibleText';
import { ImagePartial } from '@storefront/lib-global/partials/image';
import { LinkPartial } from '@storefront/lib-global/partials/link';
import {
  TypographyStyleMapping,
  TypographyStylesType,
  colours,
  maxWidthPartial,
  media,
  spacing,
} from '@storefront/lib-global/stylings';
import { getLocalizedValue, transformTextToId } from '@storefront/lib-global/utils/transformers';

const textAlignment = {
  LEFT: 'left',
  CENTRE: 'center',
  RIGHT: 'right',
};

const S = {
  Container: styled.div<{ $bgColor?: string }>`
    background-color: ${({ $bgColor }) => $bgColor};
    padding: ${spacing.S};

    @media ${media.greaterThan('lg')} {
      padding: 0 ${spacing.S};
    }

    @media ${media.greaterThan('xx')} {
      ${maxWidthPartial({ maxWidth: 1300, withPadding: false })}
    }
  `,
  List: styled.ul<{ $columns?: number; $textSize?: string }>`
    display: grid;
    grid-gap: ${spacing.XS};
    grid-template-columns: 1fr;
    margin: 0;
    padding: 0;

    ${({ $textSize }) => ($textSize ? TypographyStyleMapping[$textSize] : null)}

    @media ${media.greaterThan('lg')} {
      ${({ $columns }) => maxWidthPartial({ maxWidth: $columns && $columns > 2 ? 964 : 632, withPadding: false })}
      column-gap: ${spacing.M};
      grid-template-columns: ${({ $columns }) => ($columns ? `repeat(${$columns}, 1fr)` : 'repeat(1, 1fr)')};
      justify-items: center;
      row-gap: 20px;
    }
  `,
  ListItem: styled.li<{ $buttonColor?: string; $textColor?: string }>`
    background-color: ${({ $buttonColor }) => $buttonColor ?? colours.DARK_BLUE};
    display: flex;
    min-height: 44px;
    width: 100%;

    & a {
      align-items: center;
      border: 1px solid ${colours.DARK_BLUE};
      color: ${({ $textColor }) => $textColor ?? colours.WHITE};
      display: flex;
      gap: ${spacing.XXXS};
      height: 100%;
      justify-content: center;
      padding: ${spacing.XXXS};
      text-align: center;
      text-decoration: none;
      text-transform: uppercase;
      width: 100%;

      @media ${media.greaterThan('lg')} {
        gap: 10px;
      }
    }

    &:hover,
    &:focus {
      background-color: ${({ $buttonColor }) => $buttonColor ?? colours.NAVY_BLUE};
      opacity: ${({ $buttonColor }) => ($buttonColor ? 0.8 : 1)};
    }

    @media ${media.greaterThan('lg')} {
      min-height: 50px;
      width: 300px;
    }
  `,
  Title: styled(FlexibleTextPartial)<{ $textAlignment?: 'LEFT' | 'CENTRE' | 'RIGHT' }>`
    display: block;
    margin: 0 0 ${spacing.M} 0;
    text-align: ${({ $textAlignment }) => textAlignment[$textAlignment ?? 'LEFT']};
  `,
};

export type CTAButtonsProp = CTAButtonsSchema;

export function CTAButtons({ ctaButtons, textSize, title, bgColor }: CTAButtonsProp) {
  const isDesktop = useMediaMatch(media.greaterThan('lg'));
  const { alignment, color, textSize: titleTextSize, titleText } = title ?? {};
  const currentTitleAlignment = isDesktop ? alignment?.desktop : alignment?.mobile;
  const currentTitleColor = isDesktop ? color?.desktop : color?.mobile;
  const currentTitleSize = isDesktop ? titleTextSize?.desktop : titleTextSize?.mobile;

  const numOfButtons = ctaButtons?.length ?? 0;
  const fourOrMore = numOfButtons === 4 ? 2 : 3;
  const columns = numOfButtons <= 3 ? numOfButtons : fourOrMore;

  return (
    <S.Container $bgColor={bgColor}>
      {titleText && (
        <S.Title
          text={titleText}
          $textAlignment={currentTitleAlignment}
          textSize={currentTitleSize}
          textColor={currentTitleColor}
          textTransform="UPPERCASE"
          defaultTextColor={colours.BLACK}
          defaultTextSize={TypographyStylesType.HEADINGS_H3}
        />
      )}
      <S.List
        $columns={columns}
        $textSize={textSize === TypographyStylesType.DEFAULT ? TypographyStylesType.BUTTON_SEMIBOLD : textSize}
      >
        {ctaButtons
          .filter((ctaButton) => ctaButton.text)
          .map((ctaButton, index) => {
            const { text, buttonColor, textColor, link, icon } = ctaButton;
            const buttonText = getLocalizedValue(text);
            return (
              <S.ListItem
                key={`${index}-${transformTextToId(buttonText)}`}
                $buttonColor={buttonColor}
                $textColor={textColor}
              >
                <LinkPartial {...link}>
                  {icon?.image ? (
                    <ImagePartial
                      layout="intrinsic"
                      altText={icon.altText}
                      src={icon.image.name ?? ''}
                      assetType={icon.assetType}
                      height={icon.image && 18}
                      width={icon.image && 18}
                      defaultHost={icon.image.defaultHost}
                      useOriginalSize={icon.useOriginalSize}
                    />
                  ) : null}
                  {buttonText}
                </LinkPartial>
              </S.ListItem>
            );
          })}
      </S.List>
    </S.Container>
  );
}
